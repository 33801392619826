import Vue from "vue";

/*TITLE*/
document.title = "Residencial Natura | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Natura";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Natura";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-ac5-viena-1l-einsenhut-193x1383--20230126110148.jpg";
Vue.prototype.$image_kitchen = "cocina-bottega-acero-602x602-etimoe-ice-botteha-antracita--20230126110156.jpg";
Vue.prototype.$image_bath1 = "banop-vela-natural-602x602-vela-natural-333x592-limit-vela-natural-333x592--20230126110114.jpg";
Vue.prototype.$image_bath2 = "banosec-matika-silver-602x602-matika-silver-333x592-stripe-matika-silver-333x592--20230126110123.jpg";
Vue.prototype.$image_room = "habp-ac5-viena-1l-einsenhut-193x1383--20230126110131.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-ac5-viena-1l-einsenhut-193x1383--20230126110148.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-bottega-acero-602x602-etimoe-ice-botteha-antracita--20230126110156.jpg",
  },
  {
    src: "cocina-bottega-acero-602x602-blanco-nubol-bottega-acero--20230126110102.jpg",
  },
  {
    src: "cocina-bottega-acero-602x602-bison-bottega-acero--20230126110107.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-vela-natural-602x602-vela-natural-333x592-limit-vela-natural-333x592--20230126110114.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-matika-silver-602x602-matika-silver-333x592-stripe-matika-silver-333x592--20230126110123.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "habp-ac5-viena-1l-einsenhut-193x1383--20230126110131.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://clientes.ppgstudios.com/porcelanosa/residencial_natura/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/805581494?h=7cbc8f5280";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/residencialnatura/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=av%20derechos%20humanos%20almassera%2046132",
    text: "Av. Derechos Humanos - Almassera 46132",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=av%20derechos%20humanos%20almassera%2046132",
    text: "Av. Derechos Humanos - Almassera 46132",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:606463050",
    text: "606463050",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@rismar-sa.com",
    text: "info@rismar-sa.com",
  },
];
